import React, { Component } from 'react';
import Root from './Root';
import { Route, Routes, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { LocalizationProvider } from './components/shared/ReactToolbox';
import Login from './components/login/Login';
import Dashboard from './components/dashboard/Dashboard';
import AuthenticatedRoute from './components/login/AuthenticatedRoute';
import QuestionairesList from './components/questionaires/QuestionairesList';
import Questionaire from './components/questionaires/Questionaire';
import FieldDefinitionsList from './components/fields/FieldDefinitionsList';

const WithParams = ({ component: Component, ...restProps }) => {
  const { id, questionaire, ...restParams } = useParams();
  return <Component
    {...{
      ...id ? { id: parseInt(id) } : {},
      ...questionaire ? { questionaire: parseInt(questionaire) } : {},
      ...restParams,
      ...restProps,
    }}
  /> ;
}

const App = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <Root>
        <LocalizationProvider lang='nl'>
          <ToastContainer hideProgressBar={true} newestOnTop={true} />
          
          <Routes>
            <Route path="/login" element={<Login />} />
              <Route
                exact
                path="/"
                element={<AuthenticatedRoute component={Dashboard} />}
              >
              <Route
                path=""
                index={true}
                element={
                  <>
                    <QuestionairesList />
                    <FieldDefinitionsList />
                  </>
                }
              />
              <Route
                path=':id'
                element={<Questionaire />}
              />
            </Route>
          </Routes>
        </LocalizationProvider>
      </Root>
    </DndProvider>
  );
}

export default App;