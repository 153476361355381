import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Form } from 'react-bootstrap';

import { mapToProps } from '../../redux/Factory';
import { FormInput, FormSelect } from '../shared/ReactToolbox';

const FieldsFormField = ({ value = [], onChange, answer, questionaire }) => {
  
  const { fieldDefinitionsList } = useSelector(mapToProps.fieldDefinitions);
  const { selectionOptionsList } = useSelector(s => mapToProps.selectionOptions(s, {}));

  const fields = value
    .filter(fl => !!fl)
    .reduce((o, field) => ({ ...o, [field.definition]: field }), {}) || {}
  
  return (
    questionaire.field_definitions.map((id, key) => {
      if (!fieldDefinitionsList || !fieldDefinitionsList[id]) return <i>Niet gevonden</i>;
      
      const { name, resourcetype } = fieldDefinitionsList[id];
      
      const initialState = {
        TextFieldDefinition: { value: '' },
        SelectionFieldDefinition:  { value: null },
        UrlFieldDefinition:  { value: '', description: '' }
      };

      const translateResourcetype = {
        TextFieldDefinition: 'TextField',
        SelectionFieldDefinition: 'SelectionField',
        UrlFieldDefinition: 'UrlField',
      };
      
      const setValue = fieldName => value => onChange(
        Object.values({
          ...fields,
          [id]: {
            ...fields[id] || {
              ...initialState[resourcetype],
              questionaire: questionaire.id,
              ...answer ? { answer : answer.id } : {},
              resourcetype: translateResourcetype[resourcetype],
              definition: id,
            },
            [fieldName]: value,
          },
        }));
      const field = fields[id] || {};
      
      const formControls = {
        TextFieldDefinition:
          <FormInput value={field.value || ''} onChange={setValue('value')} />,
        SelectionFieldDefinition:
          <FormSelect
            list={selectionOptionsList[id]}
            value={field.value || ''}
            onChange={setValue('value')}
            formatTitle={({ name }) => name}
          />,
        UrlFieldDefinition: 
          <>
            <FormInput
              value={field.value || ''}
              onChange={setValue('value')}
              placeholder='Url'
            />
            <FormInput
              style={{ marginTop: '5px' }}
              value={field.description || ''}
              onChange={setValue('description')}
              placeholder='Beschrijving'
            />
          </>,
      };
      return (
        <div className="form-control" style={{ marginTop: '5px' }} key={key}>  
          {name && <Form.Label>{name}</Form.Label>}
          {formControls[resourcetype]}
        </div>
      );
      })
  )
}
export default FieldsFormField;