
import Field from '../fields/Field';

export const getAnswerColumns = ({ questionaire, fieldDefinitionsList }) => ([
  {
    name: 'Kosten',
    selector: ({ name }) => name,
  },
  ...questionaire.field_definitions.map((id, key) => {
    if (!fieldDefinitionsList || !fieldDefinitionsList[id]) {
      return {
        name: <i>Niet gevonden</i>,
        selector: () => null,
      }
    }
    const fieldDefinition = fieldDefinitionsList[id];
    return {
      name: fieldDefinition.name,
      selector: ({ fields }) =>
        <Field
          field={fields.find(({ definition }) => definition === id )}
          fieldDefinition={fieldDefinition}
        />,
    };
  }),
]);