import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { ListGroup, Card, Container, Row } from 'react-bootstrap';
import { BsQuestionSquare } from 'react-icons/bs';
import _ from 'lodash';

import {
  CreateEditModal as CreateModal,
  CreateEditModal as EditModal,
  CreateButton,
  LoadingIndicator,
  DeleteConfirmButton,
} from '../shared/ReactToolbox';
import { mapToProps, actions } from '../../redux/Factory';
import AnswersList from '../answers/AnswersList';

const ChoicesList = ({
  getChoicesIsLoading,
  questionaire,
  choicesList,
  createChoice,
  createChoiceIsLoading,
  updateChoice,
  updateChoiceIsLoading,
  deleteChoice,
  deleteChoiceIsLoading,
  parent,
  level,
}) => {
  const [showNewModal, setShowModal] = useState(false);
  const [choiceInEditModal, setChoiceInEditModal] = useState(null);
  if (getChoicesIsLoading) return <LoadingIndicator />;
    
  const formFields = {
    name: { label: 'Naam' },
  };
  
  return (
    <div style={{ marginLeft: '20px' }}>
      {choicesList && Object.values(choicesList).map((choice, key) => {
        const Choice = () => {
          const { choicesList } = useSelector(s => mapToProps.choices(s, { parent: choice }));
          const { answersList } = useSelector(s => mapToProps.answers(s, { choice }));

          return (
            <div>
              <Card style={{ minWidth: '25rem', marginTop: '5px' }}>
                <Card.Body
                  onClick={() => setChoiceInEditModal(choice)}
                >
                  <BsQuestionSquare style={{ marginRight: '10px' }} />
                  {choice.name}
                  <DeleteConfirmButton
                    modalTitle="Keuze verwijderen"
                    onDelete={() => deleteChoice(choice)}
                    loading={deleteChoiceIsLoading}
                    className="float-end"
                  />

                </Card.Body>
              </Card>
              {
                (
                  (questionaire.choices_count === null && _.isEmpty(choicesList))
                  || questionaire.choices_count === level
                ) &&
                  <AnswersList choice={choice} questionaire={questionaire} level={level + 1}/>
              }
              {
                (
                  (questionaire.choices_count === null && _.isEmpty(answersList))
                  || (questionaire.choices_count !== null && questionaire.choices_count !== level)
                ) &&
                  <ChoicesListConnected parent={choice} questionaire={questionaire} level={level + 1}/>
              }
            </div>
          );
        }
        return <Choice key={key} />
      })}
      <CreateButton
        onClick={() => setShowModal(true)}
        style={{ marginTop: '5px'}}
      >
        <BsQuestionSquare />
      </CreateButton>
      
      {choiceInEditModal &&
        <EditModal
          show={!!choiceInEditModal}
          modalTitle="Keuze bewerken"
          loading={updateChoiceIsLoading}
          onHide={() => setChoiceInEditModal(null)}
          initialState={choiceInEditModal}
          formFields={formFields}

          onSave={choice => updateChoice(
          choice,
          { callback: () => setChoiceInEditModal(null) }
          )}
        />
      }
      
      {showNewModal &&
        <CreateModal
          modalTitle="Nieuwe keuze"
          loading={createChoiceIsLoading}
          onHide={() => setShowModal(false)}
          includeData={{ questionaire: questionaire.id}}
          initialState={{ name: '', parent: parent ? parent.id : null }}
          formFields={formFields}

          onSave={newChoice => createChoice(
            newChoice,
            { callback: () => setShowModal(false) }
          )}
        />
      }
    </div>
  );
}

ChoicesList.propTypes = {
  parent: PropTypes.object,
  level: PropTypes.number,
};
ChoicesList.defaultProps = {
  parent: null,
  level: 1,
};

const ChoicesListConnected = connect(
  (state, ownProps) => ({
    ...mapToProps.choices(state, ownProps),
    ...mapToProps.answers(state, ownProps),
  }),
  actions.choices
)(ChoicesList);
export default ChoicesListConnected;